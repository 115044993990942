import { Loading } from '@rd-web-markets/shared/dist/util';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import ClaimStateAlerts from '@rd-web-markets/shared/dist/claim/ClaimStateAlerts';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useDispatch, useSelector } from 'react-redux';
import ClaimEditBreadcrumbs from '@components/shared/claim/EditForm/ClaimEditBreadcrumbs';
import ClaimEditNavigation from '@components/shared/claim/EditForm/ClaimEditNavigation';
import ClaimGeneralEditForm from '@components/shared/claim/EditForm/ClaimGeneralForm';
import ClaimEditFooter from '@components/shared/claim/EditForm/ClaimEditFooter';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import { FINANCIAL_SECTION_GENERAL_LINK, SIDEBAR_CLAIM_COSTS_LINKS, SIDEBAR_CLAIM_COSTS_LINKS_CUSTOMER } from '@rd-web-markets/market/dist/constants';
import claimService from '@rd-web-markets/shared/dist/services/claim/claim.service';

const ClaimEditGeneralPage = ({accountType}) => {
  const { claim_id } = useParams();
  const [claim, setClaim] = claimService.useGetClaim(claim_id)
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const submit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const updatedClaim = await claimService.update(claim);
      history.replace(`/${accountType}/claims/${claim_id}/financial_details/${FINANCIAL_SECTION_GENERAL_LINK}`);
    } catch(error) {
      dispatch(handleError(error));
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    let value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    
    if (['checkbox', 'radio'].includes(event.target.type) && ['false', 'true'].includes(value)) {
      // quick fix - in some places the value will come as a string instead of a boolean, which will create issues in other places
      // this way its true if it is "true" or true, and false otherwise
      value = value.toString() === 'true'
    }

    setClaim({
      ...claim,
      [event.target.name]: value,
    });
  };

  const getMenuItems = () => {
    const links = user.account_type === 'customer' ? SIDEBAR_CLAIM_COSTS_LINKS_CUSTOMER : SIDEBAR_CLAIM_COSTS_LINKS;

    return links.map((item) => {
      const linkArray = item.link.split('/');
      return {
        link: `claims/${claim.id}/${item.link}`,
        text: item.text,
        highlighted: linkArray[0] === 'edit',
      };
    })
  }

  if (!claim) return <Loading />;
  return (
    <>
      <SidebarPortal 
        headerItem={{link: `companies/${claim.company.id}/master`, text: claim.claim_group.name}} 
        menuItems={getMenuItems()}
      />
      <ClaimEditBreadcrumbs claim={claim} />
      <Card>
        <Card.Header className="mb-3">
          <ClaimEditNavigation claim={claim} page={'general'}/>
        </Card.Header>
        <Card.Body>
          <ClaimStateAlerts claim={claim} />
          <Row>
            <Col md={12}>
              <ClaimGeneralEditForm
                claim={claim}
                handleChange={handleChange}/>
            </Col>
          </Row>
          <Row className="modal-footer">
            <ClaimEditFooter
              loading={loading}
              claim={claim}
              handleSubmit={submit}
              backUrl={`/${accountType}/companies/${claim.company.id}/${claim.claim_group.id}/master`}
              continueUrl={`/${accountType}/claims/${claim.id}/edit/internal_invoice_detail`}
            />
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default ClaimEditGeneralPage
