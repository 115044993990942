import React, { useCallback, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { Table } from '@rd-web-markets/shared/dist/util';
import { SHOW_DOWNLOAD_TEMPLATE_BUTTON } from '@rd-web-markets/market/dist/constants';
import { UploadButton } from '@rd-web-markets/shared/dist/util/buttons/UploadButton';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { generateProjectQuestionnaire } from '@rd-web-markets/be/dist/services/docx_generators/project_questionnair_patcher.service';
import projectTemplateImportService from '@services/claim_groups/project_template_import.service';
import BackgroundJobStatusDisplay from '@rd-web-markets/shared/dist/jobs/BackgroundJobStatusDisplay';

const NewProjects = ({claimGroup, setClaimGroup}) => {
  const hiddenFileInputNonIt = useRef(null);
  const hiddenFileInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { lastImportStatus, setLastImportStatus, interval: fetchImportStatusInterval, setInterval } = projectTemplateImportService.useFetchLastImportStatusWithInterval({ claimGroupId: claimGroup.id, setLoading })

  if (lastImportStatus?.import_status === 'pending' && !fetchImportStatusInterval?.started) {
    fetchImportStatusInterval.start()
    setLoading(true)
  } else if (lastImportStatus?.import_status !== 'pending' && fetchImportStatusInterval?.started) {
    fetchImportStatusInterval.clear()
    loading && setLoading(false)
  }

  const handleClick = (type) => {
    if (type === 'non_it') {
      hiddenFileInputNonIt.current.click();
    } else {
      hiddenFileInput.current.click();
    }
  };

  const uploadCostTemplate = projectTemplateImportService.useCreateProjectImport({ claimGroupId: claimGroup.id, setLastImportStatus })
  const onImportFile = (e, type) => {
    return uploadCostTemplate(
      { 
        formDataJson: { file: e.target.files[0] },
        type
      }
    )
  }

  const handleDelete = async (id) => {
    if(window.confirm('Are you sure you want to delete this import')) {
      try {
        await projectTemplateImportService.delete(claimGroup.id, id);
        setClaimGroup({...claimGroup, project_template_imports: claimGroup.project_template_imports.filter(pti => pti.id !== id)});
      } catch (e) {
        dispatch(handleError(e));
      } finally {
        setLoading(false);
      }
    }
  }

  const importTableHeaders = [
    { text: t('date'), },
    { text: t('uploaded_by'), },
    { text: t('type'), },
    { text: '' },
    { text: '' },
  ];

  const sortedProjectTemplateImports = claimGroup.project_template_imports.sort((a,b) => new Date(a.created_at) > new Date(b.created_at) ? -1 : 1)

  const importTableRows = () => {
    return sortedProjectTemplateImports.map((im) => ({
      columns: [
        createTimezonedDate(new Date(im.created_at), true).toCustomLocaleTimeString(),
        im.uploaded_by,
        im.import_type,
        <a 
          href={im.download_link.url} target="_blank" rel="noreferrer"
        >
          {im.download_link.filename}
        </a>,
        <Button
          size="sm"
          variant="secondary"
          onClick={() => handleDelete(im.id)}
        >
          <span className="material-icons text-danger">delete</span>
        </Button>
      ]
    }));
  }


  return (
    <>
      <Row>
        <Col md={12} className="mt-3" >
          <BackgroundJobStatusDisplay
            jobStatusObject={lastImportStatus}
            buildErrorTableRowFn = {(importError, index) => {
              return {
                key: index,
                columns: [`${importError.message || 'Error'}: ${importError.details || importError || 'Unexpected error occured.'}`]
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={SHOW_DOWNLOAD_TEMPLATE_BUTTON ? 6 : 12} className="text-center">
          <UploadButton
            id="upload-cost-template-button"
            isImmutableUnderReview={true}
            loading={loading}
            onClick={() => handleClick('it')}
            text='It Projects'
          />
          <>
            <span className="mr-4"></span>
            <UploadButton
              id="upload-cost-template-with-projects-button"
              isImmutableUnderReview={true}
              loading={loading}
              onClick={() => handleClick('non_it')}
              text='Non It'
            />
          </>
          <input
            style={{ display: 'none' }}
            type="file"
            data-testid='costTemplateUpload'
            ref={hiddenFileInput}
            onChange={(e) => onImportFile(e, 'it')}
          ></input>
          <input
            style={{ display: 'none' }}
            type="file"
            data-testid='costTemplateUpload'
            ref={hiddenFileInputNonIt}
            onChange={(e) => onImportFile(e, 'non_it')}
          ></input>
        </Col>
        <Col md={6}>
          <div className="d-flex flex-column">
            <Button
              onClick={() => generateProjectQuestionnaire(claimGroup, 'new_it_project_document')}
              className='ml-auto mr-auto mb-2 w-75'
              variant='info'
              iconPosition='left'
              icon='download'
            >
              {t('download_new_it_project_questionnaire_file')}
            </Button>
            <Button
              onClick={() => generateProjectQuestionnaire(claimGroup, 'new_non_it_project_document')}
              className='ml-auto mr-auto w-75'
              variant='info'
              iconPosition='left'
              icon='download'
            >
              {t('download_new_non_it_project_questionnaire_file')}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{span: 6}} className='text-center border-right' >
          <Table headers={importTableHeaders} rows={importTableRows()} showControls={false} />
        </Col>
      </Row>
    </>
  )
}

export default NewProjects