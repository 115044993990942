import { buildService } from '@rd-web-markets/shared/dist/services/service';
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
const api = buildService('/questionnaire_template_imports', {
  only: ['all', 'delete']
});
const useFetchImportStatuses = _ref => {
  let {
    setLoading
  } = _ref;
  const [importStatuses, setImportStatuses, fetchImportStatuses] = serviceMethods.useFetch({
    callback: useCallback(async () => await api.all(), []),
    setLoading
  });
  return [importStatuses, setImportStatuses, fetchImportStatuses];
};
const useDelete = _ref2 => {
  let {
    setLoading,
    handleToaster,
    onSuccess
  } = _ref2;
  const {
    t
  } = useTranslation();
  const deleteModel = serviceMethods.useDelete({
    onSuccess: onSuccess,
    callback: useCallback(async templateId => {
      if (window.confirm(t('are_you_sure_you_wish_to_delete_this_questionnaire_template'))) {
        const promise = await api.delete(templateId);
        handleToaster(t('questionnaire_template_deleted_successfully'), 'success');
      }
    }, [handleToaster, t]),
    setLoading
  });
  return deleteModel;
};
export const QuestionnaireTemplateImportsService = {
  useDelete,
  useFetchImportStatuses
};