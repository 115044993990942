import React, { useState, useEffect } from 'react'
import * as QueryString from 'query-string';
import { Loader } from '@rd-web-markets/shared/dist/util/Loader'
import MasterSidebar from '@components/util/MasterSidebar'
import { useTranslation } from 'react-i18next'
import { Card, Col, Row } from 'react-bootstrap'
import Fetcher from '@rd-web-markets/shared/src/lib/services/Fetcher';
import costMatrixTemplateService from '@services/cost_matrix_template.service';

export default function MatrixTemplatePage({ handleToaster, accountType }) {
	const [loading, setLoading] = useState(false)
	const [matrixTemplate, setMatrixTemplate] = useState(null)
	const { t } = useTranslation()

	useEffect(() => {
		getTemplate();
	}, [])

	const getTemplate = async () => {
		setLoading(true);
    try {
      const response = await Fetcher(
        '/api/cost_matrix_templates'
      );
      setMatrixTemplate(response);
    } catch (error) {
      console.log(error);
    } finally { 
			setLoading(false);
		}
	}

	const hanldeUploadTempalte = async (e) => {
		setLoading(true)
		const formData = new FormData()
		formData.append('file', e.target.files[0])
		try {
			await costMatrixTemplateService.create(formData);
			window.location.reload();
		} catch (error) {
			handleToaster('error', t('upload_failed'))
		}
		setLoading(false)
	}

	const DocumentsSection = ({ title, template, type }) => {
		return (
			<>
				<Row>
					<Col md={12}>
						<h5>{title}</h5>
					</Col>
				</Row>
				<Row>
					<Col md={12} className='d-flex justify-content-center'>
						<label className="btn btn-info btn-md mr-2">
							{loading ? <Loader /> : t('upload_template')}
							<input style={{ display: 'none' }} type="file" onChange={e => hanldeUploadTempalte(e)}></input>
						</label>
						{template &&
							<a href={template.download_link.url} download={`${title}.docx`} target="_blank" rel="noreferrer">
								<label className="btn btn-info btn-md">
									{t('download_template')}
								</label>
							</a>}
					</Col>
				</Row>
			</>
		)
	}

	return (
		<>
			<MasterSidebar accountType={accountType} />

			<Card>
				<Card.Header>
					<h4>{t('matrix_template')}</h4>
				</Card.Header>
				<Card.Body>
          <DocumentsSection title={t('matrix_template')} template={matrixTemplate} />
				</Card.Body>
			</Card>
		</>
	)
}
