import { useCallback, useEffect, useState } from 'react';
import { buildFormData, buildQueryString, buildService } from '@rd-web-markets/shared/dist/services/service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';

/**
 * All methods in this file execute code equivalent to
 * try {
 *   do_something()
 *   execute_on_success()
 * } catch (e) {
 *   execute_on_error()
 * } finally {
 *   execute_on_either_success_or_error()
 * }
 */

/**
 * Used for GET /collection/:id or GET /collection requests
 * Used to fetch a single or multiple objects.
 * You can pass initialQueryParams - which will be used the first time the callback method is called from the useEffect.
 * You can then pass a queryParams JSON object each time you call the returned fetchItems method.
 * initialStateValue - is the initial state value (undefined by default). It could be [] when fetching collections or {} when fetching objects.
 * 
 * Runs the callback in a useEffect once the current react component is rendered.
 * @param {Object}  
 * @returns [item[s], setItem[s], fetchItem[s]]
 */
const useFetch = _ref => {
  let {
    callback,
    setLoading,
    initialQueryParams,
    initialStateValue,
    onSuccess,
    onError,
    onFinally
  } = _ref;
  // items can also be a single item - depending on the endpoint we hit
  const [items, setItems] = useState(initialStateValue || []);
  const fetchItems = useErrorHandling(useCallback(async queryParams => {
    setLoading && setLoading(true);
    const allItems = await callback(queryParams);
    setItems(allItems);

    // onSuccess is technically not requried, as the success code can simply be part of the callback, but is added
    // to make the api clearer.
    onSuccess && onSuccess();

    // returns the promise with data just in case we need it somewhere
    return allItems;
  }, [callback, onSuccess, setLoading]), useCallback(() => {
    setLoading && setLoading(false);
    onFinally && onFinally();
  }, [onFinally, setLoading]), useCallback(() => {
    onError && onError();
  }, [onError]));
  useEffect(() => {
    fetchItems(initialQueryParams || {});
  }, [fetchItems, initialQueryParams]);
  return [items, setItems, fetchItems];
};

/**
 * Used for GET /collection/:id or GET /collection requests
 * Used to fetch a single or multiple objects.
 * Check useFetch.
 * Note that for this method the onSuccess, onError and onFinally will be called on each fetch request.
 * 
 * Creates an interval once the current react component is rendered. The interval's repeat time is intervalTimeMs or 5000ms by default.
 * The interval can then be started and cleared at will.
 * @param {*} param0 
 * @returns { stateVar, setStateVar, fetchMethod, interval, setInterval }
 */
const useFetchWithInterval = _ref2 => {
  let {
    api,
    callback,
    setLoading,
    initialQueryParams,
    initialStateValue,
    intervalTimeMs,
    onSuccess,
    onError,
    onFinally
  } = _ref2;
  const [interval, setInterval] = useState(null);
  const [stateVar, setStateVar, fetchMethod] = useFetch({
    callback,
    setLoading,
    initialQueryParams,
    initialStateValue,
    onSuccess,
    onError,
    onFinally
  });

  // On the first render of the component where this method is used, we create the inverval.
  // At this point the interval is not started and no request is made. We need to call
  // interval.start() - to start it and interval.clear() - to clear it.
  useEffect(() => {
    const newInterval = api.base.createInterval(() => fetchMethod(), intervalTimeMs || 5000);
    setInterval(newInterval);
  }, [api.base, fetchMethod, intervalTimeMs]);
  return {
    stateVar,
    setStateVar,
    fetchMethod,
    interval,
    setInterval
  };
};
const useCreate = _ref3 => {
  let {
    createCallback,
    setLoading,
    onSuccess,
    onError,
    onFinally
  } = _ref3;
  const createModel = useErrorHandling(useCallback(async () => {
    setLoading && setLoading(true);
    const result = await createCallback();
    setLoading && setLoading(false);

    // onSuccess is technically not requried, as the success code can simply be part of the callback, but is added
    // to make the api clearer.
    onSuccess && onSuccess();
    return result;
  }, [setLoading, createCallback, onSuccess]), useCallback(() => {
    setLoading && setLoading(false);
    onFinally && onFinally();
  }, [onFinally, setLoading]), useCallback(() => {
    onError && onError();
  }, [onError]));
  return createModel;
};

/**
 * The resulting method accepts an object with the formDataJson field and any other fields.
 * It turns the formDataJson into a real form data - formData.
 * 
 * { field1: 1, value1, ..., formDataJson } => callback({ field1: value 1, ..., formData })
 * 
 * @param {*} param0 
 * @returns 
 */
const usePostFormData = _ref4 => {
  let {
    callback,
    setLoading,
    onSuccess,
    onError,
    onFinally
  } = _ref4;
  const postFormData = useErrorHandling(useCallback(async paramsObject => {
    setLoading && setLoading(true);
    const {
      formDataJson
    } = paramsObject;
    const formData = buildFormData(formDataJson);
    const result = await callback({
      ...paramsObject,
      formData
    });
    setLoading && setLoading(false);

    // onSuccess is technically not requried, as the success code can simply be part of the callback, but is added
    // to make the api clearer.
    onSuccess && onSuccess();
    return result;
  }, [setLoading, callback, onSuccess]), useCallback(() => {
    setLoading && setLoading(false);
    onFinally && onFinally();
  }, [onFinally, setLoading]), useCallback(() => {
    onError && onError();
  }, [onError]));
  return postFormData;
};
const useUpdate = _ref5 => {
  let {
    callback,
    setLoading,
    onSuccess,
    onError,
    onFinally
  } = _ref5;
  const updateModel = useErrorHandling(useCallback(async paramsObject => {
    setLoading && setLoading(true);
    const result = await callback(paramsObject);
    setLoading && setLoading(false);

    // onSuccess is technically not requried, as the success code can simply be part of the callback, but is added
    // to make the api clearer.
    onSuccess && onSuccess();
    return result;
  }, [setLoading, callback, onSuccess]), useCallback(() => {
    setLoading && setLoading(false);
    onFinally && onFinally();
  }, [onFinally, setLoading]), useCallback(() => {
    onError && onError();
  }, [onError]));
  return updateModel;
};
const useDelete = _ref6 => {
  let {
    callback,
    setLoading,
    onSuccess,
    onError,
    onFinally
  } = _ref6;
  const deleteModel = useErrorHandling(useCallback(async function () {
    setLoading && setLoading(true);
    const result = await callback(...arguments);
    setLoading && setLoading(false);

    // onSuccess is technically not requried, as the success code can simply be part of the callback, but is added
    // to make the api clearer.
    onSuccess && onSuccess();
    return result;
  }, [setLoading, callback, onSuccess]), useCallback(() => {
    setLoading && setLoading(false);
    onFinally && onFinally();
  }, [onFinally, setLoading]), useCallback(() => {
    onError && onError();
  }, [onError]));
  return deleteModel;
};
const serviceMethods = {
  useFetch,
  useFetchWithInterval,
  useUpdate,
  useDelete,
  useCreate,
  usePostFormData
};
export default serviceMethods;