import React, { useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Table } from '@rd-web-markets/shared/dist/util';
import { SHOW_DOWNLOAD_TEMPLATE_BUTTON } from '@rd-web-markets/market/dist/constants';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { UploadButton } from '@rd-web-markets/shared/dist/util/buttons/UploadButton';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import ongoingTemplateImportService from '@services/claim_groups/ongoing_template_import.service';
import claimProjectReportsListsService from '@services/lists/claim_groups/claim_project_reports_lists.service'
import { generateProjectQuestionnaire } from '@rd-web-markets/be/dist/services/docx_generators/project_questionnair_patcher.service';
import BackgroundJobStatusDisplay from '@rd-web-markets/shared/dist/jobs/BackgroundJobStatusDisplay';

const OngoingProjects = ({ claimGroup, accountType, setClaimGroup, handleToaster }) => {
  const hiddenFileInputNonIt = useRef(null);
  const hiddenFileInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { lastImportStatus, setLastImportStatus, interval: fetchImportStatusInterval, setInterval } = ongoingTemplateImportService.useFetchLastImportStatusWithInterval({ claimGroupId: claimGroup.id, setLoading })

  if (lastImportStatus?.import_status === 'pending' && !fetchImportStatusInterval?.started) {
    fetchImportStatusInterval.start()
    setLoading(true)
  } else if (lastImportStatus?.import_status !== 'pending' && fetchImportStatusInterval?.started) {
    fetchImportStatusInterval.clear()
    loading && setLoading(false)
  }

  const [reports] = claimProjectReportsListsService.useFetchAll({
    accountType,
    claimGroupId: claimGroup.id,
    setLoading
  })

  const generateOngoingProjectQuestionnaires = async (document_type) => {
    const leadConsultant = claimGroup.consultant_roles.find(role => role.role === 'claim_group_access_lead_consultant');
    const companyName = claimGroup.company.abbreviated_name;

    const ongoingProjects = reports.filter(report => report.it_type === document_type && report.project_type === 'ongoing_project')

    if (!ongoingProjects.length) {
      const itType = document_type === 'default' ? 'it' : 'not_it'
      handleToaster(t(`there_are_no_ongoing_projecs_of_type_${itType}_for_this_mission.`), 'warning')
      return
    }

    ongoingProjects.forEach(async (report) => {
      await generateProjectQuestionnaire(
        {
          ...report,
          claimGroupYear: claimGroup.from_date.split('/').reverse()[0],
          companyName,
          leadConsultant: leadConsultant.user ? leadConsultant.user.name : ''
        },
        document_type === 'not_it' ? 'ongoing_non_it_project_document' : 'ongoing_it_project_document');
    })
  }

  const handleClick = (type) => {
    if (type === 'non_it') {
      hiddenFileInputNonIt.current.click();
    } else {
      hiddenFileInput.current.click();
    }
  };

  const uploadOngoingTemplate = async (event, type) => {
    let fd = new FormData();
    fd.append('file', event.target.files[0]);
    setLoading(true);
    try {
      await ongoingTemplateImportService.create(claimGroup.id, fd, type);
      setLastImportStatus({ import_status: 'pending' })
    } catch(error) {
      dispatch(handleError(error));
    } finally {
      setLoading(false);
    }
    hiddenFileInput.current.value = '';
  }

  const importTableHeaders = [
      { text: t('date'), },
      { text: t('uploaded_by'), },
      { text: t('type'), },
      { text: '' },
      { text: '' }
  ];

  const handleDelete = async (id) => {
    if(window.confirm('Are you sure you want to delete this import')) {
      try {
        await ongoingTemplateImportService.delete(claimGroup.id, id);
        setClaimGroup({...claimGroup, ongoing_template_imports: claimGroup.ongoing_template_imports.filter(pti => pti.id !== id)});
      } catch (e) {
        dispatch(handleError(e));
      } finally {
        setLoading(false);
      }
    }
  }

  const sortedProjectTemplateImports = claimGroup.ongoing_template_imports.sort((a,b) => new Date(a.created_at) > new Date(b.created_at) ? -1 : 1)

  const importTableRows = () => {
    return sortedProjectTemplateImports.map((im) => ({
      columns: [
        createTimezonedDate(new Date(im.created_at), true).toCustomLocaleTimeString(),
        im.uploaded_by,
        im.import_type,
        <a 
          href={im.download_link.url} target="_blank" rel="noreferrer"
        >
          {im.download_link.filename}
        </a>,
        <Button
          size="sm"
          variant="secondary"
          onClick={() => handleDelete(im.id)}
        >
          <span className="material-icons text-danger">delete</span>
        </Button>
      ]
    }));
  }


  return (
    <>
      <Row>
        <Col md={12} className="mt-3" >
          <BackgroundJobStatusDisplay
            jobStatusObject={lastImportStatus}
            buildErrorTableRowFn = {(importError, index) => {
              return {
                key: index,
                columns: [`${importError.message || 'Error'}: ${importError.details || importError || 'Unexpected error occured.'}`]
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={SHOW_DOWNLOAD_TEMPLATE_BUTTON ? 6 : 12} className="text-center">
          <UploadButton
            id="upload-cost-template-button"
            isImmutableUnderReview={true}
            loading={loading}
            onClick={() => handleClick('it')}
            text='It Projects'
          />
          <>
            <span className="mr-4"></span>
            <UploadButton
              id="upload-cost-template-with-projects-button"
              isImmutableUnderReview={true}
              loading={loading}
              onClick={() => handleClick('non_it')}
              text='Non It'
            />
          </>
          <input
            style={{ display: 'none' }}
            type="file"
            data-testid='costTemplateUpload'
            ref={hiddenFileInput}
            onChange={(e) => uploadOngoingTemplate(e, 'it')}
          ></input>
          <input
            style={{ display: 'none' }}
            type="file"
            data-testid='costTemplateUpload'
            ref={hiddenFileInputNonIt}
            onChange={(e) => uploadOngoingTemplate(e, 'non_it')}
          ></input>
        </Col>
        <Col md={6}>
          <div className="d-flex flex-column">
            <Button
              onClick={() => generateOngoingProjectQuestionnaires('default')}
              className='ml-auto mr-auto mb-2 w-75'
              variant='info'
              iconPosition='left'
              icon='download'
            >
              {t('download_ongoing_it_project_questionnaire_file')}
            </Button>
            <Button
              onClick={() => generateOngoingProjectQuestionnaires('not_it')}
              className='ml-auto mr-auto w-75'
              variant='info'
              iconPosition='left'
              icon='download'
            >
              {t('download_ongoing_non_it_project_questionnaire_file')}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{offset: 3, span: 6}} className='text-center'>
          <Table headers={importTableHeaders} rows={importTableRows()} showControls={false} />
        </Col>
      </Row>
    </>
  )
}

export default OngoingProjects