import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { buildFormData } from '@rd-web-markets/shared/dist/services/service';

const costMatrixTemplateService = {
  create(formData) {
    return fetch(`/api/cost_matrix_templates`, {
      ...request.post,
      headers: {
        'Accept': 'application/json, text/plain, */*'
      },
      body: formData,
    });
  },
}

export default costMatrixTemplateService;