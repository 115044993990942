const de_translations = {
  industry_references: 'Branchenbezug',
  company_references: 'Unternehmensbezug',
  intensive_interrogation: 'Intensive Befragung',
  was_the_customer_questioned_intensively: 'Wurde Kunde intensiv befragt, bis eine schlüssige konkrete Darstellung des Ziels möglich war?',
  summary: 'Zusammenfassung',
  were_the_problem_motivation_and_the_desired_goal_summarized: 'Wurden die Problemstellung, Motivation und das angestrebte Ziel während des Austausch mit dem Kunden zusammengefasst und wurde die Zusammenfassung durch den Kunden bestätigt?',
  discussion_with_colleagues: 'Diskussion mit Kollegen',
  was_the_description_discussed_intensively: 'Wurde die Beschreibung "Ziel des Vorhabens" intensiv mit mind. einem Kollegen besprochen und gemeinsam "abgesegnet"',
  motivation: 'Motivation',
  do_i_have_captivating_introduction: 'Habe ich eine "fesselnde" Einleitung: i.e. wird die Motivation plakativ und greifbar geschildert?',
  objectives_envisaged_results: 'Ziele und vorgesehene Ergebnisse',
  are_the_project_goals_recorded: 'Sind die Projektziele möglichst konkret erfasst? Soweit möglich Ziele SMART (spezifisch = konkret, messbar, attraktiv, realistisch, terminiert) formuliert? ',
  structure: 'Struktur',
  can_i_already_recognize_structure_in_the_goal: 'Kann ich im Ziel bereits eine Struktur/Reihenfolge erkennen, die in den anderen Kapiteln aufgegriffen werden kann?',
  are_bad_words_on_target: 'Sind Unworte im Ziel? (Muss umformuliert werden, sonst ergibt ein grünes ja keinen Sinn)',
  have_terms_and_formulations_been_mentioned: 'Sind Begrifflichkeiten und Formulieren analog zu anderen Kapitel genannt worden (WICHTIG: roter Faden)?',
  have_abbreviations_been_introduced_or_are_technical_terms: 'Sind Abkürzungen eingeführt, bzw.Fachtermini verständlich?',
  work_schedule: 'Arbeitsplan',
  is_there_a_work_plan: 'Liegt ein Arbeitsplan vor? Ggf. Musterarbeitsplan zuschicken',
  can_clear_rnd_activities: 'Lassen sich aus dem Arbeitsplan eindeutige FuE-Aktivitäten zum Vorhaben ableiten?',
  can_work_packages_be_divided_into_concept: 'Können Arbeitspakete in Konzept-, Entwicklungs und Testphase unterteilt werden? ',
  security_queries: 'Sicherheitsabfragen',
  is_there_a_structured_description: 'Ist eine strukturierte Beschreibung vorhanden?',
  key_point_structure_in_phases: 'Stichpunktartige Gliederung in Phasen/Arbeitspaketen Achtung! Testphase immer inkl. Rückkopplung d. Ergebnisse in Entwicklung',
  is_the_working_methodology_described: 'Ist die Arbeitsmethodik beschrieben (Sprints, Meilensteine, Parallel, iterativ….)?',
  can_internal_and_external_activities_be_clearly_defined: 'Können interne und externe Tätigkeiten klar abgegrenzt/aufgezeigt werden?',
  have_certain_terms_and_formulations_from_the_goal_been_consistently_incorporated_into_the_work_phases: 'Sind bestimmte Begrifflichkeiten und Formulierungen aus dem Ziel konsistent in den Arbeitspaketen/Phasen eingearbeitet worden?',
  market_research: 'Marktrecherche',
  have_project_delimitations_been_checked_and_compared: 'Sind Vorhabenabgrenzungen mit Produkten/Methoden/Dienstleistungen von Wettbewerbern überprüft und verglichen worden?',
  can_qualitative_and_quantitative_delimitation_features_be_listed: 'Können qualitative und quantitative Abgrenzungsmerkmale aufgeführt werden?',
  has_the_state_of_research_or_development_in_the_industry_been_briefy_described: 'Wurde kurz der Forschungs- bzw. Entwicklungsstand der Branche beschrieben?',
  have_all_new_scientifc_methods_approaches_been_mentioned: 'Wurden alle neuen zum Einsatz kommenden wissenschaftlichen/technischen Methoden bzw. Lösungsansätze oder Vorgehensweisen genannt?',
  has_it_been_checked_whether_there_is_a_clear_demarcation: 'Wurde überprüft, ob eine klare Abgrenzung zum Markt wie Somit ist kein vergleichbares Produkt auf dem Markt vorhanden oder der Firma sind keine vergleichbaren Produkte, ... bekannt vorkommt?',
  have_i_picked_up_the_structure_from_the_goal_here_as_well: 'Habe ich auch hier wieder die Struktur/Reihenfolge aus dem Ziel aufgegriffen?',
  does_the_customer_have_similar_products_or_is_he_already_advertising: 'Hat der Kunde ähnliche Produkte oder bewirbt er das Vorhaben bereits auf der Website?Ggf. Abgrenzung notwendig',
  routine: 'Routine',
  what_are_routine_activities_for_the_company: 'Was sind Routinetätigkeiten für das Unternehmen?',
  how_do_the_new_rnd_activities_difer_from_routine_activities: 'Wie grenzen sich die neuen FuE-Aktivitäten von Routinetätigkeiten ab?',
  what_was_new_to_achieve_the_goal: 'Was wurde alles neu gemacht (Produkt/Verfahren/Methodik/Organisation), um das Ziel zu erreichen?',
  has_it_been_checked_whether_no_routine_occurs: 'Wurde überprüft, ob u.a. keine Routine im Kapitel vorkommt?',
  are_there_any_bad_words_in_the_description: 'Sind Unworte in der Beschreibung? z.B. Weiterentwicklung, weiterentwickeln, optimieren',
  have_terms_and_formulations_been_mentioned_analogous_to_other_chapters: 'Sind Begrifflichkeiten und Formulieren analog zu anderen Kapitel genannt worden (WICHTIG: roter Faden)?',
  risks: 'Risiken',
  which_technical_risks_have_arisen: 'Welche technischen Risiken/Unwägbarkeiten/Herausforderungen sind aufgetreten/könnten auftreten? ',
  has_it_been_carefully_checked_whether_it_is_not_just_hard_work: 'Wurde genau geprüft, ob es sich nicht um reine "Fleißarbeit" handelt?',
  are_risks_quantifiable: 'Sind Risiken quantifizierbar?',
  conficting_goals: 'Zielkonflikte und Abbruchkriterien',
  are_there_technical_conficts_of_interest: 'Gibt es technische Zielkonflikte, die bei Nicht-Erreichen zum Projektabbruch führen?',
  are_the_technical_risks_of_interest_consistent: 'Sind die technischen Risiken/Zielkonflikte konsistent zu den Zielen und den Beschreibungen der Arbeiten?',
  have_i_picked_up_the_structure_sequence_from_the_goal_here_as_well: 'Habe ich auch hier wieder die Struktur/Reihenfolge aus dem Ziel aufgegriffen?',
  has_the_common_consistency_from_the_descriptions_been_checked_with_at_least_one_colleague: 'Wurde die gemeinsame Konsistenz aus den Beschreibungen Ziel des Vorhabens, Beschreibung der Arbeiten, Technische Risiken intensiv mit mind. einem Kollegen überprüft und gemeinsam abgesegnet?',
  natural_sciences: 'Naturwissenschaften',
  engineering_and_technology: 'Ingenieurwissenschaften und Technologie',
  medical_and_health_sciences: 'Medizinische und Gesundheitswissenschaften',
  agricultural_sciences_and_veterinary_medicine: 'Agrarwissenschaften und Veterinärmedizin',
  social_sciences: 'Sozialwissenschaften',
  humanities_and_arts: 'Geisteswissenschaften und Kunst',
  mathematics: 'Mathematik',
  informatics_and_information_sciences: 'Informatik und Informationswissenschaften',
  physics: 'Physik',
  chemistry: 'Chemie',
  geosciences_and_associated_environmental_sciences: 'Geowissenschaften und zugehörige (Umwelt-)Wissenschaften',
  biology: 'Biologie',
  other_natural_sciences: 'Andere Naturwissenschaften',
  civil_engineering: 'Bauingenieurwesen',
  electrical_engineering_electronics_information_technology: 'Elektrotechnik, Elektronik, Informationstechnik',
  mechanical_engineering: 'Maschinenbau',
  chemical_process_engineering: 'Chemische Verfahrenstechnik',
  materials_engineering: 'Werkstofftechnik',
  medical_technology: 'Medizintechnik',
  environmental_engineering: 'Umwelttechnik',
  environmental_biotechnology: 'Umweltbiotechnologie',
  industrial_biotechnology: 'Industrielle Biotechnologie',
  nanotechnology: 'Nanotechnologie',
  other_engineering_sciences_and_technologies: 'Andere Ingenieurwissenschaften und Technologien',
  basic_medicine: 'Grundlagenmedizin',
  clinical_medicine: 'Klinische Medizin',
  health_sciences: 'Gesundheitswissenschaften',
  medical_biotechnology: 'Medizinische Biotechnologie',
  other_medical_sciences: 'Andere medizinische Wissenschaften',
  agriculture_and_forestry: 'Land- und Forstwirtschaft, Fischerei',
  animal_husbandry_and_dairy_and_dairy_science: 'Tierzucht sowie Milch- und Molkereiwissenschaft',
  veterinary_medicine: 'Veterinärmedizin',
  agricultural_biotechnology: 'Agrarbiotechnologie',
  other_agricultural_sciences: 'Andere Agrarwissenschaften',
  psychology_and_cognitive_science: 'Psychologie und Kognitionswissenschaft',
  economics_and_business_administration: 'Volks- und Betriebswirtschaftslehre',
  educational_sciences: 'Erziehungswissenschaften',
  sociology: 'Soziologie',
  jurisprudence: 'Rechtswissenschaften',
  political_science: 'Politikwissenschaft',
  social_and_economic_geography: 'Sozial- und Wirtschaftsgeografie',
  media_and_communication_studies: 'Medien- und Kommunikationswissenschaften',
  other_social_sciences: 'Andere Sozialwissenschaften',
  history_and_archeology: 'Geschichte und Archäologie',
  languages_and_literature: 'Sprachen und Literatur',
  philosophy_ethics_religion: ' Philosophie, Ethik und Religion',
  art_visual_arts_art_history_performing_arts_music: 'Kunst (bildende Kunst, Kunstgeschichte, darstellende Kunst, Musik)',
  other_humanities: 'Andere Geisteswissenschaften',
  subcategory_related_to_the_selected_branch_of_research: 'Unterkategorie Forschungszweig',
  contact_person: 'Ansprechpartner',
  collaborative_research_with_at_least_one_other_company: 'Kooperation mit mindestens einem anderen Unternehmen und/oder einer Forschungseinrichtung?',
  branch_of_research_related_to_rnd_project: 'FuE-Forschungszweig',
  this_document_is_the_property_of_ayming: 'Dieses Dokument ist Eigentum von Ayming',
  min_3_keywords: 'Mindestens 3 Schlagwörter',
  which_work_steps_are_used: 'Welche Arbeitsschritte/Aufgaben und Methoden werden/wurden zur Erreichung des Ziels angewendet? Inwieweit werden dabei (für Ihr Unternehmen und/oder den Wirtschaftszweig) neuartige Ansätze verfolgt?',
  is_the_project_aimed_at_a_product: 'Zielt das Vorhaben auf ein Produkt, Produktionsverfahren, eine Produktionslinie, eine Dienstleistung oder eine wissenschaftliche Methodik ab, das/die eine deutliche Weiterentwicklung/Neuheit in Bezug auf den betreffenden Wirtschaftszweig darstellt?',
  project_have_a_specific_connection: 'Besteht ein konkreter Bezug des Vorhabens zu bestehenden Produkten, Produktionslinien, Produktionsverfahren, Dienstleistungen oder bereits etablierter wissenschaftlicher Methodik in Ihrem Unternehmen?',
  explain_which_engineering: 'Erläutern Sie, welche ingenieurs-, natur- geistes-, oder sozialwissenschaftlichen Hemmnisse für den Lösungsansatz Ihres Vorhabens identifiziert werden können. Beschreiben Sie die wissenschaftlichen (bezogen auf alle Wissenschaftszweige) und/oder die technischen Hemmnisse, Risiken, Unwägbarkeiten oder Herausforderungen, die zum Scheitern des Vorhabens führen könnten. Beschreiben Sie, wie Sie mit vorliegenden Risiken umgehen. Erläutern Sie kurz Meilensteine und Abbruchkriterien. Wirtschaftlich und/oder finanzielle Risiken sind nicht zu benennen!',
  financial_and_personnel_framework: 'Finanzieller und Personeller Rahmen',
  were_the_personnel_expenses_funded_within_the_framework_of_other_funding: 'Wurden/Werden die Personalaufwendungen und/oder Auftragskosten für Aufträge innerhalb der EU/des EWR für dieses FuE-Vorhaben im Rahmen anderer Förderungen oder staatlicher Beihilfen gefördert?',
  research_allowance_project_profile: 'Forschungszulage: Vorhabensteckbrief',
  project_profile: 'Vorhabensteckbrief',
  key_data: 'Eckdaten',
  from: 'Von',
  to: 'Bis',
  all_works_involved: 'Beschreibung der Arbeiten',
  contact_person_customer: 'Ansprechpartner Kunde',
  role: 'Funktion',
  phone_number: 'Telefon',
  mobile_number: 'Mobile',
  department: 'Abteilung',
  ayming_contact_person: 'Ansprechpartner Ayming',
  type_of_rnd: 'Tätigkeitsform',
  word_yes: 'Ja',
  word_no: 'Nein',
  yes: 'Ja',
  no: 'Nein',
  complete_in_house_research: 'Vollständige eigenbetriebliche Forschung?',
  contract_research: 'Auftragsforschung ?',
  personnel_framework_must_be_specified: 'Der personelle Rahmen muss für jedes (angefangene) Kalenderjahr, dass das Vorhaben umfasst, angegeben werden',
  one_person_month: '1 Personenmonat (PM) = 20 Personentage',
  rnd_stakeholders: 'FuE-Beteiligte',
  departments_involved: 'Beteiligte Abteilungen',
  number_of_employees: 'Anzahl der Mitarbeitenden',
  are_all_employees_employed_by_the_named_company: 'Sind alle Mitarbeitenden beim Antragsteller angestellt ?',
  internal_project_numbers: 'Interne Projektnummern',
  number: 'Anzahl',
  name: 'Name',
  notes: 'Bemerkung',
  content_and_technical_information: 'Inhaltliche Angaben',
  project_name: 'Projektname',
  keywording: 'Schlagworte',
  company_institute_name: 'Name Auftragnehmer',
  contracting_partners_type: 'Art',
  legal_form: 'Rechtsform',
  address: 'Adresse',
  street: 'Straße',
  house_number: 'Hausnummer',
  additional_house_number_details: 'Hausnummerzusatz',
  city: 'Ort',
  post_code: 'PLZ',
  additional_address_details: 'Adressergänzung',
  country: 'Land',
  eu_eea: 'EU/EWREU/EEA',
  description_of_work: 'Beschreibung aller Arbeiten',
  estimated: 'Schätzung',
  invoice: 'Rechnung',
  offer: 'Angebot',
  comments: 'Kommentar',
  collaborative_research: 'Kooperationsforschung',
  company_university_research_institute_name: 'Name Kooperationspartner',
  financial_framework_in_euro: 'Finanzieller Rahmen [in EUR]',
  financial_framework_of_the_project_total: 'Finanzieller Rahmen des Vorhabens (gesamt)',
  rnd_personnel_costs: 'davon FuE-Personalkosten',
  rnd_material_costs: 'davon FuE-Sachkosten und Kosten für Investitionen (nicht förderfähig, nur grob abschätzen)',
  rnd_contract_costs_within_eu: 'davon FuE-Auftragskosten für Auftragnehmer innerhalb EU/EWR',
  rnd_contract_costs_not_in_eu: 'davon FuE-Auftragskosten für Auftragnehmer außerhalb EU/EWRR',
  rnd_other_costs: 'davon sonstige FuE-Kosten (nicht förderfähig, nur grob abschätzen)',
  personnel_framework_in_pm: 'Personeller Rahmen [in PM]',
  personnel_framework_of_the_project_total: 'Personeller Rahmen des Vorhabens (gesamt)',
  personnel_framework_of_the_project_scientists: 'davon Akademiker',
  personnel_framework_of_the_project_technicians: 'davon Techniker',
  personnel_framework_of_the_project_others: 'davon sonstige',
  additional_information: 'Zusätzliche Informationen:',
  staff_cost_per_day: 'Stunden pro Tag',
  staff_cost_per_hour: 'Stundensatz',
  first_fast_check: 'Erster Schnellcheck',
  date_of_the_kickoff: 'Kick-off Datum',
  should_the_project_advance_date: 'Go/No-Go Entscheidung Antragstellung  - Datum',
  criteria: 'Kriterien',
  rating_estimation: 'Bewertung/Einschätzung',
  rough_cost_calculation: 'Grobe Kostenabschätzung',
  novel: 'Neuartig',
  transferable: 'Übertragbar',
  creative: 'Schöpferisch',
  systematic: 'Systematisch',
  uncertain: 'Ungewiss',
  projects_duration: 'Projektlaufzeit',
  fulfilled: 'erfüllt',
  partially_fulfilled: 'bedingt erfüllt',
  not_fulfilled: 'nicht erfüllt',
  last_changes: 'Letzte Änderung',
  page: 'Seite',
  additional_demand: 'Nachforderung',
  additional_claim_info: 'Angaben zur Nachforderung',
  received_on: 'Eingegangen am',
  period_for_reply: 'Frist zur Beantwortung',
  project_id: 'Vorhabens-ID',
  original_text: 'Originaltext der BSFZ-Nachforderung',
  research_allowance_additional_requirement: 'Forschungszulage: Nachforderung',
  answering_questions: 'Beantwortung der Fragen',
  please_answer_the_questions_in_the_following_table: 'Bitte beantworten Sie die Fragen in der folgenden Tabelle formlos.',
  queries: 'Rückfrage',
  answers: 'Antwort',
  germany: 'Deutschland',
  afghanistan: 'Afghanistan',
  albania: 'Albanien',
  algeria: 'Algerien',
  andorra: 'Andorra',
  angola: 'Angola',
  antigua: 'Antigua & Deps',
  argentina: 'Argentinien',
  armenia: 'Armenien',
  australia: 'Australien',
  austria: 'Österreich',
  azerbeijan: 'Aserbaidschan',
  bahamas: 'Bahamas',
  bahrain: 'Bahrain',
  bangladesh: 'Bangladesch',
  barbados: 'Barbados',
  belarus: 'Belarus',
  belgium: 'Belgien',
  belize: 'Belize',
  benin: 'Benin',
  bhutan: 'Bhutan',
  bolivia: 'Bolivien',
  bosnia: 'Bosnien-herzegowina',
  botsuana: 'Botsuana',
  brazil: 'Brazilien',
  brunei: 'Brunei',
  bulgaria: 'Bulgarien',
  burkina: 'Burkina',
  burundi: 'Burundi',
  cambogia: 'Kambodscha',
  camerun: 'Kamerun',
  canada: 'Kanada',
  capo_verde: 'Kap Verde',
  central_african_rep: 'Zentralafrikanische Vertretung',
  chad: 'Tschad',
  chile: 'Chile',
  china: 'China',
  columbia: 'Kolumbien',
  cameroon: 'Komoren',
  congo: 'Kongo',
  congo_democratic: 'Kongo Demokratische Republik',
  costa_rica: 'Costa Rica',
  croatia: 'Kroatien',
  cuba: 'Kuba',
  cyprus: 'Zypern',
  czech_republic: 'Tschechische Republik',
  denmark: 'Dänemark',
  djibouti: 'Dschibuti',
  dominica: 'Dominica',
  dominican_republic: 'Dominikanische Republik',
  east_timor: 'Osttimor',
  ecuador: 'Ecuador',
  egypt: 'Ägypten',
  el_salvador: 'El Salvador',
  equatorial_guinea: 'Äquatorialguinea',
  eritrea: 'Eritrea',
  estonia: 'Estland',
  ethiopia: 'Äthiopien',
  fiji: 'Fidschi',
  finland: 'Finnland',
  france: 'Frankreich',
  gabon: 'Gabun',
  gambia: 'Gambia',
  georgia: 'Georgien',
  ghana: 'Ghana',
  greece: 'Griechenland',
  grenada: 'Grenada',
  guatemala: 'Guatemala',
  guinea: 'Guinea',
  guinea_bissau: 'Guinea-Bissau',
  guyana: 'Guyana',
  haiti: 'Haiti',
  honduras: 'Honduras',
  hungary: 'Ungarn',
  iceland: 'Island',
  india: 'Indien',
  indonesia: 'Indonesien',
  iran: 'Iran',
  irak: 'Irak',
  ireland: 'Irland Republik',
  israel: 'Israel',
  italy: 'Italien',
  ivory_coast: 'Elfenbeinküste',
  jamaica: 'Jamaika',
  japan: 'Japan',
  jordan: 'Jordanien',
  kazachstan: 'Kasachstan',
  kenia: 'Kenia',
  kiribati: 'Kiribati',
  north_korea: 'Korea Nord',
  south_korea: 'Südkorea Süd',
  kosovo: 'Kosovo',
  kuwait: 'Kuwait',
  kirgyzstan: 'Kirgistan',
  laos: 'Laos',
  latvia: 'Lettland',
  lebanon: 'Libanon',
  lesotho: 'Lesotho',
  liberia: 'Liberia',
  libiya: 'Libyen',
  liechenstein: 'Liechtenstein',
  lithuania: 'Litauen',
  luxembourg: 'Luxemburg',
  macedonia: 'Mazedonien',
  madagaskar: 'Madagaskar',
  malawi: 'Malawi',
  malaysia: 'Malaysien',
  maldives: 'Malediven',
  mali: 'Mali',
  malta: 'Malta',
  marshall_islands: 'Marshallinseln',
  mauritania: 'Mauretanien',
  mauritius: 'Mauritius',
  mexico: 'Mexiko',
  micronesia: 'Mikronesien',
  moldova: 'Republik Moldau',
  monaco: 'Monaco',
  mongolia: 'Mongolei',
  montenegro: 'Montenegro',
  marocco: 'Marokko',
  mozambique: 'Mosambik',
  myanmar: 'Myanmar, Birma',
  namibia: 'Namibia',
  nauru: 'Nauru',
  nepal: 'Nepal',
  netherlands: 'Niederlande',
  new_zealand: 'Neuseeland',
  nicaragua: 'Nicaragua',
  niger: 'Niger',
  nigeria: 'Nigeria',
  norwegia: 'Norwegen',
  oman: 'Oman',
  pakistan: 'Pakistan',
  palau: 'Palau',
  panama: 'Panama',
  papua_new_guinea: 'Papua-Neuguinea',
  paraguay: 'Paraguay',
  peru: 'Peru',
  philippines: 'Philippinen',
  poland: 'Polen',
  portugal: 'Portugal',
  qatar: 'Katar',
  romania: 'Rumänien',
  russian_federation: 'Russische Föderation',
  rwanda: 'Ruanda',
  st_kitts_nevis: 'St. Kitts und Nevis',
  st_lucia: 'St. Lucia',
  st_vincent: 'St. Vincent und die Grenadinen',
  samoa: 'Samoa',
  san_marino: 'San Marino',
  sao_tome: 'São Tomé & Príncipe',
  saudi_arabia: 'Saudi-Arabien',
  senegal: 'Senegal',
  serbia: 'Serbien',
  seychelles: 'Seychellen',
  sierra_leone: 'Sierra Leone',
  singapoore: 'Singapur',
  slovakia: 'Slowakei',
  slovenia: 'Slowenien',
  solomon_islands: 'Salomonen',
  south_africa: 'Südafrika',
  south_sudan: 'Südsudan',
  spain: 'Spanien',
  sri_lanka: 'Sri Lanka',
  sudan: 'Sudan',
  suriname: 'Suriname',
  swaziland: 'Swasiland',
  sweeden: 'Schweden',
  switzerland: 'Schweiz',
  syria: 'Syrien',
  taiwan: 'Taiwan',
  tajikistan: 'Tadschikistan',
  tanzania: 'Tansania',
  thailand: 'Thailand',
  togo: 'Togo',
  Tonga: 'Tonga',
  trinidad_and_tobago: 'Trinidad und Tobago',
  tunis: 'Tunesien',
  turkey: 'Turkei',
  turkmenistan: 'Turkmenistan',
  tuavalu: 'Tuvalu',
  uganda: 'Uganda',
  ukraine: 'Ukraine',
  united_arab_emirates: 'Vereinigte Arabische Emirate',
  united_kingdom: 'Vereinigtes Königreich',
  united_states: 'USA',
  uruguay: 'Uruguay',
  uzbekistan: 'Usbekistan',
  vanuatu: 'Vanuatu',
  vatican_city: 'Vatikanstadt',
  venezuela: 'Venezuela',
  vietnam: 'Vietnam',
  yemen: 'Jemen',
  sambia: 'Sambia',
  zimbabwe: 'Zimbabwe',
  baden_wuerttemberg: 'Baden-Wurttemberg',
  bayern: 'Bayern',
  berlin: 'Berlin',
  brandenburg: 'Brandenburg',
  bremen: 'Bremen',
  hamuburg: 'Hamburg',
  hesse: 'Hessen',
  mecklenburg_vorpommern: 'Mecklenburg-Vorpommern',
  lower_saxony: 'Niedersachsen',
  north_rhine_westphalia: 'Nordrhein-Westfalen',
  rheinland_pfalz: 'Rheinland-Pfalz',
  saarland: 'Saarland',
  saxony: 'Sachsen',
  saxony_anhalt: 'Sachsen-Anhalt',
  schleswig_holstein: 'Schleswig-Holstein',
  thuringia: 'Thuringen',

  //elsterdocx
  transmission_protocol: 'Übertragungsprotokoll',
  general_information_about_the_company_1: '1. Allgemeine Angaben zum Unternehmen',
  application_for_research_allowance: 'Antrag auf Forschungszulage',
  application_for_research_allowance_body: 'Bitte beachten Sie, dass Sie für jedes Wirtschaftsjahr nur einen Antrag auf Forschungszulage stellen können. Das gilt auch, wenn Sie mehrere begünstigte Forschungs- und Entwicklungsvorhaben in einem Wirtschaftsjahr durchgeführt haben.',
  business_address: 'Geschäftsanschrift',
  address_at_home_abroad: 'Geschäftsanschrift',
  legal_representative: 'Gesetzlicher Vertreter',
  information_on_tax_exemption: 'Angaben zur Steuerbefreiung',
  information_on_the_distribution: 'Angaben zur Verteilung der maximal förderfähigen Bemessungsgrundlage bei verbundenen Unternehmen',
  information_on_affiliated_companies: 'Angaben zu verbundenen Unternehmen ',
  gci_please_note_that_only: 'Bitte beachten Sie, dass nur die verbundenen Unternehmen einzutragen sind, die ebenfalls einen Antrag auf Forschungszulage gestellt haben oder stellen werden.',
  information_on_total_wage_cost_in_the_enterprise_during_the_financial_year: 'Angaben zu Lohnaufwendungen insgesamt im Unternehmen im Wirtschaftsjahr ',
  information_on_wage_costs_of_employees_engaged_in_research: 'Angaben zu Lohnaufwendungen der in Forschungs- und Entwicklungsvorhaben tätigen Arbeitnehmer insgesamt im Unternehmen im Wirtschaftsjahr ',
  additional_information_for_partnership: 'Additional information for partnerships',
  additional_information_for_partnership_subtitle: 'Empfangsbevollmächtigter nach §122 Absatz 6 der Abgabenordnung für den Bescheid über die gesonderte und einheitliche Feststellung von Anteilen an der festgesetzten Forschungszulage',
  details_of_the_parties_involved_in_the_assessment: 'Angaben zu den Feststellungsbeteiligten am Ende des Wirtschaftsjahres',
  information_on_breakdown_11: 'Angaben zur Aufteilung der Forschungszulage nach gezeichnetem oder eingezahltem Kapital oder nach Bruchteilen',

  for_the_financial_year: 'Für das Wirtschaftsjahr',
  tax_reference: 'Steuernummer',
  company_name: 'company_name',
  location: 'location',
  industry: 'industry',
  tax_group: 'tax_group',
  postal_code: 'Postleitzahl',
  place: 'Ort',
  country_in_case_of_foreign_countries: 'Staat (im Falle Ausland)',
  salutation: 'Anrede',
  forename: 'Vorname',
  gci_4_the_company_exempt: 'Das Unternehmen ist nach § 5 des KStG von der Besteuerung befreit.',
  gci_4_the_research_and_dev: 'Die nachfolgend erklärten Forschungs- und Entwicklungsvorhaben wurden im Rahmen eines wirtschaftlichen Geschäftsbetriebs oder eines nicht steuerbefreiten Betriebs gewerblicher Art von juristischen Personen des öffentlichen Rechts durchgeführt.',
  gci_5_it_is_an_affiliated: 'Es handelt sich um ein verbundenes Unternehmen im Sinne von § 3 Absatz 6 FZulG. Maßgeblich sind die Verhältnisse am Ende des Wirtschaftsjahres.',
  gci_5_has_a_company_affiliated: 'Hat ein mit Ihnen verbundenes Unternehmen für Wirtschaftsjahre, die im gleichen Kalenderjahr enden in dem Ihr Wirtschaftsjahr endet, Forschungszulage beantragt beziehungsweise wird ein mit Ihnen verbundenes Unternehmen Forschungszulage beantragen?',
  gci_5_will_the_max_eligible_ex: 'Wird die maximal förderfähige Bemessungsgrundlage für verbundene Unternehmen in Höhe von 4 Millionen € überstiegen?',
  gci_5_maximum_eligible_ass: 'Maximale förderfähige Bemessungsgrundlage, die für diesen Antrag auf Forschungszulage berücksichtigt werden soll. ',
  gci_6_tax_number: 'Steuernummer des verbundenen Unternehmens',
  gci_6_name_of_the_aff: 'Name des verbundenen Unternehmens',
  gci_6_if_available: 'Soweit vorhanden:  Datum des Antrags auf Forschungszulage Ggf. Datum des Bescheids auf Forschungszulage gci_6_if_availablegci_6_if_available',
  gci_7_total_number_of: 'Anzahl der im Unternehmen beschäftigten Arbeitnehmer, für die ein Lohnsteuerabzug vorgenommen wird, insgesamt im Wirtschaftsjahr (nach Vollzeitäquivalenten)',
  gci_7_number_of_employees: 'Anzahl der im Unternehmen beschäftigten Arbeitnehmer, für die aufgrund einer DBA-Regelung kein Lohnsteuerabzug vorgenommen wird, insgesamt im Wirtschaftsjahr (nach Vollzeitäquivalenten)',
  gci_7_gross_wages_granted_deduction: 'Bruttoarbeitslöhne, die der Arbeitgeber den Arbeitnehmern unmittelbar gewährt und von dem der Lohnsteuerabzug gemäß § 38 Absatz 1 EStG vorgenommen wurde, insgesamt im Wirtschaftsjahr',
  gci_7_gross_wages_granted_pursuant: 'Bruttoarbeitslöhne, die der Arbeitgeber den Arbeitnehmern unmittelbar gewährt und für die der Lohnsteuerabzug gemäß § 38 Absatz 1 EStG aufgrund einer DBA-Regelung nicht vorgenommen wird, insgesamt im Wirtschaftsjahr',
  gci_7_expenditure: 'Ausgaben für die Zukunftssicherung der Arbeitnehmer nach § 3 Nummer 62 EStG insgesamt im Wirtschaftsjahr',
  gci_8_total_number: 'Anzahl der im Unternehmen mit Forschungs- und Entwicklungsvorhaben beschäftigten Arbeitnehmer, für die ein Lohnsteuerabzug vorgenommen wird, insgesamt im Wirtschaftsjahr (nach Vollzeitäquivalenten)',
  gci_8_number_of_employees: 'ShapeShapeAnzahl der im Unternehmen mit Forschungs- und Entwicklungsvorhaben beschäftigten Arbeitnehmer, für die aufgrund einer DBA-Regelung kein Lohnsteuerabzug vorgenommen wird, insgesamt im Wirtschaftsjahr (nach Vollzeitäquivalenten)',
  gci_8_gross_wages_deducted: 'ShapeShapeBruttoarbeitslöhne, die der Arbeitgeber den mit Forschungs- und Entwicklungsvorhaben betrauten Arbeitnehmern unmittelbar gewährt und von denen der Lohnsteuerabzug gemäß § 38 Absatz 1 EStG vorgenommen wurde, insgesamt im Wirtschaftsjahr',
  gci_8_gross_wages_pursuant: 'ShapeShapeBruttoarbeitslöhne, die der Arbeitgeber den mit Forschungs- und Entwicklungsvorhaben betrauten Arbeitnehmern unmittelbar gewährt und für die der Lohnsteuerabzug gemäß § 38 Absatz 1 EStG aufgrund einer DBA-Regelung nicht vorgenommen wurde, insgesamt im Wirtschaftsjahr',
  gci_8_expenditure: 'ShapeShapeAusgaben für die Zukunftssicherung der mit Forschungs- und Entwicklungsvorhaben betrauten Arbeitnehmer nach § 3 Nummer 62 EStG insgesamt im Wirtschaftsjahr',
  name_line_1: 'Namenszeile 1',
  name_line_2: 'Namenszeile 2',
  gci_10_number: 'Nummer des Feststellungsbeteiligten',
  tin: 'tin',
  identificaiton: 'Identifikationsnummer',
  type_of_participant: 'Art des Beteiligten',
  gci_11_capital: 'Kapital am Ende des Wirtschaftsjahres',


  tk_ginfo_company_1: '1. Allgemeine Angaben zum Unternehme',
  tk_iorado_projects_2: '2. Angaben zu Forschungs- und Entwicklungsvorhaben',
  tk_dem_3: 'tk_dem_3',
  tk_freq_4: 'tk_freq_4',
  tk_dt_as_5: 'tk_dt_as_5',
  tk_final_dec_6: 'tk_final_dec_6',
  tk_participation_7: 'tk_participation_7',
  tk_power_of_attorney_8: 'tk_power_of_attorney_8',
  tk_info_totwage_enterprise_finy: 'Angaben zu Lohnaufwendungen insgesamt im Unternehmen im Wirtschaftsjahr',
  tk_information_wage_costs_eng_res: 'Angaben zu Lohnaufwendungen der in Forschungs- und Entwicklungsvorhaben tätigen Arbeitnehmer insgesamt im Unternehmen im Wirtschaftsjahr',


  iorado_projects_subtitle: 'Ich beantrage die Forschungszulage für folgende Forschungs- und Entwicklungsvorhaben',
  iorado_only_for_in_house_research_and_development_projects: 'iorado_only_for_in_house_research_and_development_projects',
  iorado_wages_for_own_employees: 'iorado_wages_for_own_employees',
  iorado_own_cont_of_a_sole_proprietor: 'iorado_own_cont_of_a_sole_proprietor',
  iorado_own_cont_by_shareholders: 'iorado_own_cont_by_shareholders',
  iorado_only_for_contract_research: 'iorado_only_for_contract_research',
  iorado_contractors_data: 'iorado_contractors_data',
  only_when_received_or_applying_for_other_subsidies: 'only_when_received_or_applying_for_other_subsidies',
  only_for_corporation_projects: 'only_for_corporation_projects',
  determination_of_eligible_expenses_from_wages_title: 'determination_of_eligible_expenses_from_wages_title',
  iorado_determination_b4_2020: 'iorado_determination_b4_2020',
  iorado_determination_after_2020: 'iorado_determination_after_2020',
  tk_deminimis_section3_3_subtitle: 'tk_deminimis_section3_3_subtitle',
  dmver_erklarung_2: 'dmver_erklarung_2',
  dmver_erklarung_3: 'dmver_erklarung_3',
  dtas_determination_of_the_tax_base: 'dtas_determination_of_the_tax_base',
  dtas_determination_of_the_tax_base_subtitle1: 'dtas_determination_of_the_tax_base_subtitle1',
  dtas_determination_of_the_tax_base_subtitle2: 'dtas_determination_of_the_tax_base_subtitle2',

  dtas_table2_title: 'dtas_table2_title',
  dtas_table3_title: 'dtas_table3_title',
  dtas_table4_title: 'dtas_table4_title',
  dtas_table5_title: 'dtas_table5_title',
  dtas_table5_subtitle: 'dtas_table5_subtitle',
  dtas_table6_title: 'dtas_table6_title',
  dtas_table7_title: 'dtas_table7_title',
  dtas_table8_title: 'dtas_table8_title',
  dtas_table9_title: 'dtas_table9_title',
  dtas_table10_title: 'dtas_table10_title',
  dtas_table10_subtitle: 'dtas_table10_subtitle',
  dtas_table11_title: 'dtas_table11_title',
  dtas_table11_subtitle_1: 'dtas_table11_subtitle_1',
  dtas_table11_subtitle_2: 'dtas_table11_subtitle_2',
  dtas_table12_title: 'dtas_table12_title',
  tk_final_dec_6_long_text: 'tk_final_dec_6_long_text',
  postcode: 'postcode',

  iorado_p_1_project_id: 'Vorhabens-ID des Forschungs- und Entwicklungsvorhabens aus dem Bescheid der Bescheinigungsstelle Forschungszulage nach § 6 FZulG',
  iorado_p_1_tax_number_of: 'Steuernummer des Unternehmens, in dem das Forschungs- und Entwicklungsvorhaben durchgeführt wird.',
  iorado_p_1_name_of_researcher: 'Bezeichnung des Forschungs- und Entwicklungsvorhabens',
  iorado_p_1_art: 'Art',
  iorado_p_1_cooperation_projects: 'Kooperationsvorhaben',
  iorado_p_1_start_of_work: 'Beginn der Arbeiten / Datum der Auftragserteilung',
  iorado_p_1_the_decision_of_the: 'Der Bescheid der Bescheinigungsstelle Forschungszulage nach § 6 FZulG für dieses Forschungs- und Entwicklungsvorhaben liegt dem Finanzamt vor.',
  iorado_p_1_the_information_declared: 'Die im Rahmen des Bescheinigungsverfahrens erklärten und dem Bescheid nach § 6 FZulG zu Grunde gelegten Angaben haben sich nicht verändert.',
  iorado_p_1_further_has: 'Für dieses Forschungs- und Entwicklungsvorhaben wurden/werden weitere Fördermittel beantragt und/oder gewährt.',

  iorado_p_2_the_research_and_development: 'Das Forschungs- und Entwicklungsvorhaben wird nicht im Auftrag eines anderen Unternehmens ausgeführt',

  iorado_p_3_1: 'Anzahl der mit Forschungs- und Entwicklungstätigkeiten im Forschungs- und Entwicklungsvorhaben beschäftigten Arbeitnehmer (nach Vollzeitäquivalenten)',
  iorado_p_3_2: 'dies entspricht einer Anzahl nach Köpfen',
  iorado_p_3_3: 'Anteil Bruttoarbeitslohn, den der Arbeitgeber den Arbeitnehmern unmittelbar gewährt und von dem der Lohnsteuerabzug gemäß § 38 Absatz 1 EStG vorgenommen wurde, der auf die Forschungs- und Entwicklungstätigkeiten des Forschungs- und Entwicklungsvorhabens im Wirtschaftsjahr entfällt',
  iorado_p_3_4: 'davon Anteil Bruttoarbeitslohn an Gesellschafter einer Kapitalgesellschaft (in Zeile davor enthalten)',
  iorado_p_3_5: 'davon vor dem 01.07.2020 oder nach dem 30.06.2026 von den Arbeitnehmern bezogen',
  iorado_p_3_6: 'davon nach dem 30.06.2020 und vor dem 01.07.2026 von den Arbeitnehmern bezogen',
  iorado_p_3_7: 'Anteil Bruttoarbeitslohn, den der Arbeitgeber den Arbeitnehmern unmittelbar gewährt und von dem der Lohnsteuerabzug gemäß § 38 Absatz 1 EStG aufgrund einer DBA-Regelung nicht vorgenommen wurde, der auf die Forschungs- und Entwicklungstätigkeiten des Forschungs- und Entwicklungsvorhabens im Wirtschaftsjahr entfällt',
  iorado_p_3_8: 'davon vor dem 01.07.2020 oder nach dem 30.06.2026 von den Arbeitnehmern bezogen',
  iorado_p_3_9: 'davon nach dem 30.06.2020 und vor dem 01.07.2026 von den Arbeitnehmern bezogen',
  iorado_p_3_10: 'ShapeAnteilige Ausgaben für die Zukunftssicherung der Arbeitnehmer nach § 3 Nummer 62 EStG, die auf Forschungs- und Entwicklungstätigkeiten des Forschungs- und Entwicklungsvorhabens entfallen',
  iorado_p_3_11: 'davon vor dem 01.07.2020 oder nach dem 30.06.2026 geleistet',
  iorado_p_3_12: 'davon nach dem 30.06.2020 und vor dem 01.07.2026 geleistet',

  iorado_p_4_1: 'Eigene Forschungs- und Entwicklungstätigkeiten an dem Forschungs- und Entwicklungsvorhaben wurden geleistet',
  iorado_p_4_2: 'Eigene Arbeitsstunden für Forschungs- und Entwicklungstätigkeit in diesem Forschungs- und Entwicklungsvorhaben',
  iorado_p_4_3: 'davon vor dem 01.07.2020 oder nach dem 30.06.2025 geleistet',
  iorado_p_4_4: 'davon nach dem 30.06.2020 und vor dem 01.07.2026 geleistet',


  iorado_p_5_1: 'Name des Gesellschafters',
  iorado_p_5_2: 'Eine zivilrechtlich wirksame vertragliche Vereinbarung liegt vor',
  iorado_p_5_3: 'Tätigkeitsvergütung für Gesellschafter insgesamt',
  iorado_p_5_4: 'Tätigkeitsvergütung für Forschungs- und Entwicklungstätigkeiten in diesem Forschungs- und Entwicklungsvorhaben',
  iorado_p_5_5: 'Arbeitsstunden des Gesellschafters für Forschungs- und Entwicklungstätigkeit in diesem Forschungs- und Entwicklungsvorhaben',
  iorado_p_5_6: 'davon vor dem 01.07.2020 oder nach dem 30.06.2025 geleistet',
  iorado_p_5_7: 'davon nach dem 30.06.2020 und vor dem 01.07.2026 geleistet',


  iorado_p_6_1: 'Der Auftrag wurde nicht als Unterauftrag aus einer eigenen Beauftragung erteilt',
  iorado_p_6_2: 'An den Auftragnehmer gezahltes Entgelt im Wirtschaftsjahr insgesamt',
  iorado_p_6_3: 'davon vor dem 01.07.2020 oder nach dem 30.06.2026 geleistet',
  iorado_p_6_4: 'davon nach dem 30.06.2020 und vor dem 01.07.2026 geleistet',
  iorado_p_6_5: 'Für dieses Forschungs- und Entwicklungsvorhaben wurden/werden weitere Fördermittel beantragt und /oder gewährt.',

  iorado_p_7_1: 'Zustimmung zur Übernahme der nachrichtlichen Angaben zu diesem Forschungs- und Entwicklungsvorhaben aus dem Bescheid der Bescheinigungsstelle Forschungszulage nach § 6 FZulG wird erteilt.',
  iorado_p_7_2: 'Bezeichnung des Unternehmens',
  state: 'Staat',

  iorado_p_8_1: 'Datum des Bewilligungsbescheids / der Zusage',
  iorado_p_8_2: 'Fördermittelgeber',

  iorado_p_9_1: 'Das Forschungs- und Entwicklungsvorhaben wird in Kooperation mit verbundenen Unternehmen durchgeführt.',
  iorado_p_9_2: 'Anteil, zu dem das Forschungs- und Entwicklungsvorhaben in Kooperation mit verbundenen Unternehmen durchgeführt wird (in Prozent).',
  iorado_p_9_3: 'Das Forschungs- und Entwicklungsvorhaben wird in Kooperation mit steuerbefreiten Forschungseinrichtungen durchgeführt.',
  iorado_p_9_4: 'Anteil, zu dem das Forschungs- und Entwicklungsvorhaben in Kooperation mit steuerbefreiten Forschungseinrichtungen durchgeführt wird (in Prozent).',
  iorado_p_9_5: 'Das Forschungs- und Entwicklungsvorhaben wird in Kooperation mit anderen steuerpflichtigen Unternehmen durchgeführt.',
  iorado_p_9_6: 'Anteil, zu dem das Forschungs- und Entwicklungsvorhaben in Kooperation mit anderen steuerpflichtigen Unternehmen durchgeführt wird (in Prozent).',

  iorado_p_10_1: 'Summe der Arbeitslöhne für eigene Arbeitnehmer, soweit diese in diesem begünstigten Forschungs- und Entwicklungsvorhaben Forschungs- und Entwicklungstätigkeiten ausgeführt haben:',
  iorado_p_10_2: 'Abzüglich Aufwendungen, für die eine andere Förderung beansprucht wird/wurde',
  iorado_p_10_3: 'Förderfähige Aufwendungen aus Arbeitslöhnen für eigene Arbeitnehmer, soweit diese in diesem begünstigten Forschungs- und Entwicklungsvorhaben Forschungs- und Entwicklungstätigkeiten ausgeführt haben (vor dem 01.07.2020):',

  iorado_p_11_1: 'Summe der Arbeitslöhne für eigene Arbeitnehmer, soweit diese in diesem begünstigten Forschungs- und Entwicklungsvorhaben Forschungs- und Entwicklungstätigkeiten ausgeführt haben:',
  iorado_p_11_2: 'Abzüglich Aufwendungen, für die eine andere Förderung beansprucht wird/wurde',
  iorado_p_11_3: 'ShapeFörderfähige Aufwendungen aus Arbeitslöhnen für eigene Arbeitnehmer, soweit diese in diesem begünstigten Forschungs- und Entwicklungsvorhaben Forschungs- und Entwicklungstätigkeiten ausgeführt haben (nach dem 30.06.2020):',

  dmver_1_1: 'Im laufenden Kalenderjahr sowie in den zwei vorangegangenen Kalenderjahren wurden Beihilfen im Sinne der Verordnungen (EU) Nummer 1407/2013 der Kommission vom 18. Dezember 2013 über die Anwendung der Artikel 107 und 108 des Vertrags über die Arbeitsweise der Europäischen Union auf De-minimis-Beihilfen (De-minimis-Verordnung) oder nach der Verordnung (EU) Nummer 360/2012 (sogenannten DAWI-de-minimis-Verordnung) bezogen. dmver_1_1',

  dmver_2_1: 'Datum des Bewilligungsbescheids / der Zusage',
  dmver_2_2: 'Beihilfegeber',
  dmver_2_3: 'Beihilfewert',

  dmver_3_1: 'Datum des Bewilligungsbescheids / der Zusage',
  dmver_3_2: 'Beihilfegeber',
  dmver_3_3: 'Beihilfewert',

  fr_1_1: 'Es handelt sich um ein Unternehmen in Schwierigkeiten im Sinne von Artikel 1 Absatz 4 Buchstabe c und Artikel 2 Nummer 18 der Verordnung (EU) Nummer 651/2014 der Europäischen Kommission vom 17. Juni 2014 zur Feststellung der Vereinbarkeit bestimmter Gruppen von Beihilfen mit dem Binnenmarkt in Anwendung der Artikel 107 und 108 des Vertrags über die Arbeitsweise der Europäischen Union (AGVO) (ABl. L 187 vom 26.6.2014, Seite 1).',
  fr_1_2: 'ShapeEs liegt keine Verpflichtung zur Rückzahlung von Beihilfen aufgrund eines früheren Beschlusses der Europäischen Kommission zur Feststellung der Unzulässigkeit einer Beihilfe und ihrer Unvereinbarkeit mit dem Binnenmarkt vor, der nicht nachgekommen ist.',
  fr_1_3: 'Der begünstigte Betrieb ist ein kleines oder mittleres Unternehmen im Sinne des Anhangs I der Verordnung	 Shape(EU) Nummer 651/2014 der Kommission vom 17. Juni 2014 zur Feststellung der Vereinbarkeit bestimmter Gruppen von Beihilfen mit dem Binnenmarkt in Anwendung der Artikel 107 und 108 des Vertrags über die Arbeitsweise der Europäischen Union (AGVO) (ABl. L 187 vom 26.6.2014, Seite 1). fr_1_3',

  dos_1_1: 'Summe der förderfähigen Aufwendungen aus Arbeitslöhnen für eigene Arbeitnehmer, soweit diese in begünstigten Forschungs- und Entwicklungsvorhaben Forschungs- und Entwicklungstätigkeiten ausgeführt haben:',

  dos_2_1: 'Erklärte Arbeitsstunden eines Einzelunternehmers:',
  dos_2_2: 'davon maximal förderfähige Stunden: (Hinweis X/12 x 2.080 Stunden; dabei ist X die Anzahl der Monate des Wirtschaftsjahres zwischen dem 02.01.2020 und dem 30.06.2020 und 2.080 Stunden sind die in einem Wirtschaftsjahr maximal zulässigen Arbeitsstunden, ermittelt aus 40 Wochenarbeitsstunden x 52 Wochen)',
  dos_2_3: 'Erklärte Arbeitsstunden von Gesellschaftern einer Mitunternehmerschaft:',
  dos_2_4: 'davon maximal förderfähige Stunden: (Hinweis X/12 x 2.080 Stunden; dabei ist X die Anzahl der Monate des Wirtschaftsjahres zwischen dem 02.01.2020 und dem 30.06.2020 und 2.080 Stunden sind die in einem Wirtschaftsjahr maximal zulässigen Arbeitsstunden, ermittelt aus 40 Wochenarbeitsstunden x 52 Wochen)',
  dos_2_5: 'Summe der förderfähigen Aufwendungen aus Eigenleistungen: (Hinweis: maximal förderfähige Stunden x 40 Euro)',

  dos_3_1: 'Summe des Entgelts für Auftragsforschung',
  dos_3_2: 'Davon 60 Prozent förderfähig:',

  dos_4_1: 'Zwischensumme der förderfähigen Aufwendungen, die nach dem 01.01.2020 und vor dem 01.07.2020 entstanden sind:',

  dos_5_1: 'Summe der förderfähigen Aufwendungen aus Arbeitslöhnen für eigene Arbeitnehmer, soweit diese in begünstigten Forschungs- und Entwicklungsvorhaben Forschungs- und Entwicklungstätigkeiten ausgeführt haben:',

  dos_6_1: 'Erklärte Arbeitsstunden eines Einzelunternehmers:',
  dos_6_2: 'davon maximal förderfähige Stunden: (Hinweis X/12 x 2.080 Stunden; dabei ist X die Anzahl der Monate des Wirtschaftsjahres ab dem 01.07.2020 und 2.080 Stunden sind die in einem Wirtschaftsjahr maximal zulässigen Arbeitsstunden, ermittelt aus 40 Wochenarbeitsstunden x 52 Wochen)',
  dos_6_3: 'Erklärte Arbeitsstunden von Gesellschaftern einer Mitunternehmerschaft:',
  dos_6_4: 'davon maximal förderfähige Stunden: (Hinweis X/12 x 2.080 Stunden; dabei ist X die Anzahl der Monate des Wirtschaftsjahres ab dem 01.07.2020 und 2.080 Stunden sind die in einem Wirtschaftsjahr maximal zulässigen Arbeitsstunden, ermittelt aus 40 Wochenarbeitsstunden x 52 Wochen)',
  dos_6_5: 'Summe der förderfähigen Aufwendungen aus Eigenleistungen: (Hinweis: maximal förderfähige Stunden x 40 Euro)',

  dos_7_1: 'Summe des Entgelts für Auftragsforschung',
  dos_7_2: 'Davon 60 Prozent förderfähig:',

  dos_8_1: 'Zwischensumme der förderfähigen Aufwendungen, die nach dem 30.06.2020 entstanden sind:',

  dos_9_1: 'Förderfähige Aufwendungen, die nach dem 01.01.2020 und vor dem 01.07.2020 entstanden sind (höchstens 2.000.000 Euro):',
  dos_9_2: 'Summe der förderfähigen Aufwendungen, die nach dem 01.01.2020 und vor dem 01.07.2020 entstanden sind (höchstens 2.000.000 Euro) und der förderfähigen Aufwendungen, die nach dem 30.06.2020 entstanden sind:',
  dos_9_3: 'Förderfähige Bemessungsgrundlage (Summe der förderfähigen Aufwendungen im Wirtschaftsjahr, höchstens 4.000.000 Euro):',
  dos_9_4: 'Maximal förderfähige Bemessungsgrundlage bei verbundenen Unternehmen',

  dos_10_1: 'Beantragte Forschungszulage (vorbehaltlich der Prüfung der förderfähigen Eigenleistungen durch Ihr Finanzamt):',

  dos_11_1: 'Feststellung des geförderten Eigenanteils aufgrund der Begrenzung der Bemessungsgrundlage im 1. Halbjahr: Ansatz des Bemessungsgrundlagenhöchstbetrages bis 30.06.2020, aber förderfähige Bemessungsgrundlage unter 4.000.000 Euro',
  dos_11_2: 'Feststellung des geförderten Eigenanteils aufgrund der Begrenzung der Bemessungsgrundlage  im 2. Halbjahr: Ansatz des Bemessungsgrundlagen-höchstbetrags von 4.000.000 Euro bei der förderfähigen Bemessungsgrundlage:',
  dos_11_3: 'Die beantragte Forschungszulage enthält keine De-minimis-Beihilfe. Da Ihre förderfähigen Lohnaufwendungen und/oder die förderfähigen Aufwendungen der Auftragsforschung die Bemessungsgrundlagenhöchstgrenze bereits erreichen, entfällt die beantragte Forschungszulage nicht auf Eigenleistungen.',
  dos_12_1: 'Summe der förderfähigen Eigenleistungen',
  dos_12_2: 'Der auf die Eigenleistungen entfallende Teil der Forschungszulage beträgt: (25 Prozent der Summe der förderfähigen Eigenleistungen):',
  dos_12_3: 'Beihilfewert bereits erhaltener De-minimis-Beihilfen (im Kalenderjahr und den zwei vorausgegangenen Jahren):',
  dos_12_4: ' Beihilfewert bereits erhaltener De-minimis-Beihilfen (im Kalenderjahr und den zwei vorausgegangenen Jahren)',
  dos_12_5: 'Die Forschungszulage wird unter Berücksichtigung der De-Minimis-Verordnung beantragt in Höhe von:',

  final_declaration_1: 'Ich erkläre, dass ich die vorstehenden Angaben wahrheitsgemäß nach bestem Wissen und Gewissen gemacht habe und sie durch entsprechende Unterlagen belegen kann. Mir ist bekannt, dass die Angabe falscher Tatsachen sowie das Unterlassen einer Anzeige strafrechtliche Folgen nach sich ziehen können (§§ 370, 153 AO). Ich erkläre ferner, dass ich die Anwendung der Verordnung (EG) Nummer 1407/2013 der Kommission vom 18. Dezember 2013 als Rechtsgrundlage anerkenne und dass mir bekannt ist, dass die vorstehenden Angaben steuerlich erhebliche Tatsachen im Sinne von § 370 AO sind. Außerdem ist mir bekannt, dass zu den steuerlich erheblichen Tatsachen insbesondere etwaige Sachverhalte gehören, die durch Scheingeschäfte und Scheinhandlungen verdeckt werden, sowie Rechtsgeschäfte oder Handlungen unter Missbrauch von Gestaltungsmöglichkeiten im Zusammenhang mit der in Anspruch genommenen Forschungszulage. Das gilt auch für nachträgliche Änderungen von Vereinbarungen oder Rechtshandlungen, die mit dem Ziel vorgenommen werden, den Zeitpunkt des Beginns des Investitionsvorhabens oder des Investitionsabschlusses in eine Zeit, die eine Forschungszulage bewirkt, zu verlegen, um dadurch eine Forschungszulage oder eine höhere Forschungszulage in Anspruch zu nehmen. Ich werde dem Finanzamt unverzüglich anzeigen, wenn sich Angaben in diesem Antrag nachträglich ändern, die Auswirkungen auf die Festsetzung der Forschungszulage haben.',
  final_declaration_2: 'ShapeIch bestätige, dass ich die Unterrichtungen zum Schutz und zur Veröffentlichung der im Rahmen des Antrags auf Forschungszulage übermittelten personenbezogenen Daten zur Kenntnis genommen habe und stimme der Veröffentlichung zu.',

  participation_1_1: 'Der Antrag wurde unter Mitwirkung eines Angehörigen der steuerberatenden Berufe im Sinne der §§ 3 und 4 StBerG angefertigt.',
  ort: 'Ort',
  adiitional_information: 'Zusätzliche Angaben',
  mandator_number: 'Mandantennummer',
  editor_identifier: 'Bearbeiterkennzeichen',
  power_of_attorney_1_1: 'Der Forschungszulagenbescheid soll einem abweichenden Empfänger zugesandt werden.',
}

export default de_translations
