import { patchDocument, PatchType, TextRun } from 'docx';
import * as QueryString from 'query-string';
import ProjectQuestionnaireTemplateService from '../project_questionnaire_template.service';
import convertHTMLToPlainText from '@rd-web-markets/shared/dist/util/helpers/convertHTMLToPlainText';
const getDocumentName = (projectData, questionnaire_type) => {
  switch (questionnaire_type) {
    case 'new_it_project_document':
      return 'New IT Project Questionnaire.docx';
    case 'new_non_it_project_document':
      return 'New Non-IT Project Questionnaire.docx';
    case 'ongoing_it_project_document':
      return "Ongoing IT ".concat(projectData.claimGroupYear, "-Project-").concat(projectData.project_name, " Questionnaire.docx");
    case 'ongoing_non_it_project_document':
      return "Ongoing Non-IT ".concat(projectData.claimGroupYear, "-Project-").concat(projectData.project_name, " Questionnaire.docx");
    default:
      return 'Project Questionnaire.docx';
  }
};
const ONGOING_QUESTIONNAIRE_TYPES = ['ongoing_non_it_project_document', 'ongoing_it_project_document'];
const getPatcherObject = projectDataField => {
  let text = projectDataField || '';
  if (!text.length) {
    text = 'No update.';
  }
  return {
    type: PatchType.PARAGRAPH,
    children: [new TextRun(text)]
  };
};
const getNewDocumentPatches = projectData => {
  const leadConsultant = projectData.consultant_roles.find(role => role.role === 'claim_group_access_lead_consultant');
  const companyName = projectData.company.abbreviated_name;
  return {
    lead_consultant: getPatcherObject(leadConsultant.user ? leadConsultant.user.name : ''),
    company_name: getPatcherObject(companyName)
  };
};

/**
 * Returns a new TextRun that has children TextRuns that each except the first have a line break before them if they contain text.
 * 
 * @returns 
 */
const getPatcherObjectFromMultipleFields = (projectData, fieldNames) => {
  projectData || (projectData = {});
  const fullText = fieldNames.map(fieldName => projectData[fieldName] || '').join('');
  if (!fullText.trim().length) {
    return {
      type: PatchType.PARAGRAPH,
      children: [new TextRun({
        text: 'No update.'
      })]
    };
  }
  const textRuns = fieldNames.map((fieldName, index) => {
    const text = convertHTMLToPlainText(projectData[fieldName]) || '';
    let lineBreaksBeforeCount = 1;

    // break adds a new line BEFORE the text run. No need for a new line if there is no text in this project field, or if it is the FIRST Text Run, because there is already an existing line break.
    if (index === 0 || !text.length) {
      lineBreaksBeforeCount = 0;
    }
    return new TextRun({
      text,
      break: lineBreaksBeforeCount
    });
  });
  return {
    type: PatchType.PARAGRAPH,
    children: textRuns
  };
};
const getOngoingDocumentPatches = (projectData, questionnaire_type) => {
  const commonPatches = {
    company_consultant: getPatcherObject(projectData.company_consultant_text),
    client_text_field: getPatcherObject(projectData.client_text),
    project_title: getPatcherObject(projectData.project_name),
    project_code: getPatcherObject(projectData.internal_project_number),
    project_manager: getPatcherObject(projectData.manager_name),
    project_start_date: getPatcherObject(projectData.start_date),
    project_end_date: getPatcherObject(projectData.end_date),
    partnership: getPatcherObject(projectData.partnership_contact_details),
    previous_year_project_type: getPatcherObject(projectData.previous_year_project_type === 'new_project' ? 'New Project' : 'Update'),
    previous_year: getPatcherObject(convertHTMLToPlainText(projectData.previous_claim_group_year))
  };
  if (questionnaire_type === 'ongoing_it_project_document') {
    var _projectData$previous, _projectData$previous2, _projectData$previous3, _projectData$previous4, _projectData$previous5, _projectData$previous6;
    return {
      ...commonPatches,
      // Its either an ongoing questionnaire in which case it we need 1 field or a new questionnaire in which case we need 3 fields.
      // If we have the field it_context_changes - the prior year was a new project.
      it_context_objectives: (_projectData$previous = projectData.previous_questionnaire) !== null && _projectData$previous !== void 0 && _projectData$previous.it_context_changes ? getPatcherObjectFromMultipleFields(projectData.previous_questionnaire, ['it_context_objectives', 'it_context_changes']) : getPatcherObject(convertHTMLToPlainText((_projectData$previous2 = projectData.previous_questionnaire) === null || _projectData$previous2 === void 0 ? void 0 : _projectData$previous2.it_context_objectives)),
      it_technical_aspects_project_phases: getPatcherObject(convertHTMLToPlainText((_projectData$previous3 = projectData.previous_questionnaire) === null || _projectData$previous3 === void 0 ? void 0 : _projectData$previous3.it_technical_aspects_project_phases)),
      it_technical_aspects_major_workers_faced: getPatcherObject(convertHTMLToPlainText((_projectData$previous4 = projectData.previous_questionnaire) === null || _projectData$previous4 === void 0 ? void 0 : _projectData$previous4.it_technical_aspects_major_workers_faced)),
      it_technical_aspects_any_form_of_development: getPatcherObject(convertHTMLToPlainText((_projectData$previous5 = projectData.previous_questionnaire) === null || _projectData$previous5 === void 0 ? void 0 : _projectData$previous5.it_technical_aspects_any_form_of_development)),
      it_results_resulted_in_commercialization: getPatcherObject(convertHTMLToPlainText((_projectData$previous6 = projectData.previous_questionnaire) === null || _projectData$previous6 === void 0 ? void 0 : _projectData$previous6.it_results_resulted_in_commercialization))
    };
  } else {
    var _projectData$previous7, _projectData$previous8, _projectData$previous9, _projectData$previous10, _projectData$previous11, _projectData$previous12, _projectData$previous13;
    return {
      ...commonPatches,
      // its either an ongoing questionnaire in which case it we need 1 field or a new questionnaire in which case we need 3 fields.
      context_of_project_historical_information: (_projectData$previous7 = projectData.previous_questionnaire) !== null && _projectData$previous7 !== void 0 && _projectData$previous7.non_it_context_phases_of_project ? getPatcherObject(convertHTMLToPlainText((_projectData$previous8 = projectData.previous_questionnaire) === null || _projectData$previous8 === void 0 ? void 0 : _projectData$previous8.non_it_context_phases_of_project)) : getPatcherObjectFromMultipleFields(projectData.previous_questionnaire, ['non_it_context_in_company', 'non_it_context_scientific_advances', 'non_it_context_phases']),
      non_it_reporting_types: getPatcherObject(convertHTMLToPlainText(((_projectData$previous9 = projectData.previous_questionnaire) === null || _projectData$previous9 === void 0 ? void 0 : _projectData$previous9.non_it_reporting_types) || ((_projectData$previous10 = projectData.previous_questionnaire) === null || _projectData$previous10 === void 0 ? void 0 : _projectData$previous10.non_it_technical_aspects_types_of_rnd_activities))),
      non_it_context_in_company: getPatcherObject(convertHTMLToPlainText(projectData.non_it_context_in_company)),
      non_it_context_scientific_advances: getPatcherObject(convertHTMLToPlainText(projectData.non_it_context_scientific_advances)),
      non_it_context_phases: getPatcherObject(convertHTMLToPlainText(projectData.non_it_context_phases)),
      non_it_technical_aspects_types_of_rnd_activities: getPatcherObject(convertHTMLToPlainText(projectData.non_it_technical_aspects_types_of_rnd_activities)),
      non_it_technical_aspects_major_technical_uncertanties: getPatcherObject(convertHTMLToPlainText((_projectData$previous11 = projectData.previous_questionnaire) === null || _projectData$previous11 === void 0 ? void 0 : _projectData$previous11.non_it_technical_aspects_major_technical_uncertanties)),
      non_it_results_lead_to_marketing_of_product: getPatcherObject(convertHTMLToPlainText((_projectData$previous12 = projectData.previous_questionnaire) === null || _projectData$previous12 === void 0 ? void 0 : _projectData$previous12.non_it_results_lead_to_marketing_of_product)),
      non_it_results_knowledge: getPatcherObject(convertHTMLToPlainText((_projectData$previous13 = projectData.previous_questionnaire) === null || _projectData$previous13 === void 0 ? void 0 : _projectData$previous13.non_it_results_knowledge))
    };
  }
};
export const generateProjectQuestionnaire = async (projectData, questionnaire_type) => {
  const {
    download
  } = ProjectQuestionnaireTemplateService;
  download(QueryString.stringify({
    questionnaire_type
  })).then(blob => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const arrayBufferData = event.target.result;
      patchDocument(arrayBufferData, {
        outputType: "nodebuffer",
        patches: ONGOING_QUESTIONNAIRE_TYPES.includes(questionnaire_type) ? getOngoingDocumentPatches(projectData, questionnaire_type) : getNewDocumentPatches(projectData)
      }).then(finaldata => {
        const blob = new Blob([finaldata], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        });
        // download the file
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = getDocumentName(projectData, questionnaire_type);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      });
    };
    reader.readAsArrayBuffer(blob);
  }).catch(error => {
    console.error('Error fetching template.docx:', error);
  });
};