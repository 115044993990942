import React from 'react'
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { SHOW_CLAIM_GROUP_TASKS } from '@rd-web-markets/market/dist/constants';

const iconStyles = {
  height: '1.2rem',
  width: '1.2rem',
  marginTop: '9px',
  fontWeight: 600
};

const ClaimGroupListClientsByCompany = ({claimGroups, loading, accountType}) => {
  const { t } = useTranslation();

  const renderTaskIconStatus = (status, categoryTitle) => {
    switch(status) {
      case 'not_started':
        return (
          <div className='claim-task-status-badge' data-testid={`icon-${categoryTitle}-badge-not_started`}>
            <span style={iconStyles} className="material-icons md-18">close</span>
          </div>
        )
      case 'in_progress':
        return (
          <div className='claim-task-status-badge'  data-testid={`icon-${categoryTitle}-badge-in_progress`}>
            <span className="material-icons md-18" style={iconStyles}>format_align_center</span>
          </div>
        )
      case 'completed':
        return (
          <div className='claim-task-status-badge' data-testid={`icon-${categoryTitle}-badge-success`}>
            <span className="material-icons md-18" style={iconStyles}>done</span>
          </div>
        )
      default:
        <></>
    }
  }

  const renderTasks = (claimGroup) => {
    return (
      claimGroup.tasks.map((task) => {
        return (
          <div className='mr-4' key={task.category_title}>
            <div className='text-center'>
              {renderTaskIconStatus(task.status, task.category_title)}
            </div>
            <div className='mt-3'>
              <span>{t(task.category_title)}</span>
            </div>
          </div>
        );
      })
    )
  };

  const renderEmptyList = () => {
    return (
      <>
      </>
    )
  }

  return (
    <>
      {claimGroups.map((claimGroup) => (
        <Card className='claim-group-list-card p-4' >
          <h3 className='mb-3'>{claimGroup.name}</h3>
          <div className='d-flex flex-row justify-content-between'>
            {
              claimGroup.tasks.length && (
                SHOW_CLAIM_GROUP_TASKS ?
                  renderTasks(claimGroup)
                  :
                  renderEmptyList()
              )
            }
            <div className='projects-block pl-4'>
              <h3 className='text-center font-weight-bold'>{claimGroup.projects_count}</h3>
              <div className='mt-4'>{t('projects')}</div>
            </div>
            <div>
              <h3 className='text-center font-weight-bold'>{claimGroup.in_progress_projects}</h3>
              <div className='mt-4'>{t('in_progress')}</div>
            </div>
            <div className='costs-block pr-4'>
              <h3 className='text-center font-weight-bold'>{claimGroup.cost_collected_projects}</h3>
              <div className='mt-4'>{t('cost_collected')}</div>
            </div>

            <div>
              <Button
                size='md'
                as={Link}
                className='claim-group-list-view-button mt-4 mr-4'
                to={
                  claimGroup.company.dashboard_type === 'business_unit_intensity_breakdown' ?
                    `/${accountType}/claim_groups/${claimGroup.id}/regular_realtime_dashboard/${claimGroup.company.id}`
                    :
                    `/${accountType}/claim_groups/${claimGroup.id}/dashboard/${claimGroup.company.id}`
                }
              >
                View Claim <span className='material-icons md-18'>arrow_forward</span>
              </Button>
            </div>
          </div>
        </Card>
      ))}
    </>
  )
}

export default ClaimGroupListClientsByCompany
